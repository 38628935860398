import { $, $$, $id, offset } from './helper/utils';
import outline from './helper/outline';
import inView from './helper/inView';
import smoothscroll from 'smoothscroll-polyfill';

outline();
smoothscroll.polyfill();

const sliderMap = new Map();
const sliderDragging = new Map();

export default function () {
  // if ($('.name') !== null) {
  //   import('./components/name.js')
  //     .then(name => name.default())
  //     .catch((err) => {
  //       console.warn('Chunk name.js loading failed', err);
  //     });
  // }
  
  if ($('.program-filter') !== null) {
    import('./pages/program.js')
      .then(program => program.default('.filter-search'))
      .catch((err) => {
        console.warn(`Chunk program.js loading failed: ${err}`);
      });
  }
  
  // if ($('.swiper-container') !== null) {
  //   import('./components/slider.js')
  //     .then(slider => slider.default())
  //     .catch((err) => {
  //       console.warn(`Chunk slider.js loading failed: ${err}`);
  //     });
  // }
  
  if ($('#countdown') !== null) {
    import('./components/countdown.js')
      .then(countdown => countdown.default())
      .catch((err) => {
        console.warn(`Chunk countdown.js loading failed: ${err}`);
      });
  }
  
  if ($('.nav-tabs') !== null || $('.accordion') !== null) {
    const fadeTab = (e) => {
      const href = e.relatedTarget.getAttribute('href');
      TweenMax.fromTo(href, 0.3, { opacity: 0 }, { opacity: 1, ease: Linear.easeNone });
    };
    
    import('../libs/bs-4-native')
      .then((bsn) => {
        if ('bootstrapNative' in window === false) {
          window.bootstrapNative = bsn;
        }
        const tabs = $$('.nav-tabs .tab-link');
        for (let i = 0; i < tabs.length; i++) {
          const el = tabs[i];
          
          // fade in new tab content
          el.addEventListener('hide.bs.tab', fadeTab, false);
        }
      })
      .catch((err) => {
        console.warn('Chunk bs4-native.js loading failed', err);
      });
  }

  // if ($('.siema') !== null) {
  //   import('./components/slider.js')
  //     .then(slider => slider.default(sliderMap, sliderDragging))
  //     .catch((err) => {
  //       console.warn('Chunk slider.js loading failed', err);
  //     });
  // }
  //
  // const lightboxes = $$('.lightbox');
  // inView(lightboxes, () => {
  //   import('./components/lightbox.js')
  //     .then(lightbox => lightbox.default('.lightbox', sliderMap, sliderDragging))
  //     .catch((err) => {
  //       console.warn('Chunk lightbox.js loading failed', err);
  //     });
  // }, true);
 
  // const bsElements = $$('.nav-tabs, [data-toggle="collapse"]');
  // inView(bsElements, () => {
  //   const fadeTab = (e) => {
  //     const href = e.relatedTarget.getAttribute('href');
  //     TweenMax.fromTo(href, 0.3, { opacity: 0 }, { opacity: 1, ease: Linear.easeNone });
  //   };

  //   import('../libs/bs-4-native')
  //     .then((bsn) => {
  //       if ('bootstrapNative' in window === false) {
  //         window.bootstrapNative = bsn;
  //       }
  //       const tabs = $$('.nav-tabs .tab-link');
  //       for (let i = 0; i < tabs.length; i++) {
  //         const el = tabs[i];

  //         // fade in new tab content
  //         el.addEventListener('hide.bs.tab', fadeTab, false);
  //       }
  //     })
  //     .catch((err) => {
  //       console.warn('Chunk bs4-native.js loading failed', err);
  //     });
  // }, true);
  
  const loadMobileNav = () => {
    import('./components/mobileNav.js').then(mobileNav => {
      return mobileNav.init();
    }).catch(err => {
      console.warn(`Chunk mobileNav.js loading failed: ${err}`);
    });
  };
  loadMobileNav();
  
  const toProgramm = $('#toProgramm');

  if (toProgramm !== null) {
    toProgramm.addEventListener('click', function (e) {
      e.preventDefault();
      const href = toProgramm.getAttribute('href');
      const target = $id(href.replace('#', ''));

      if (target !== null) {
        window.scrollTo({
          top: offset(target).top,
          behavior: 'smooth',
        });
      }
    });
  }
}
